@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.blockContainerFifth {
  width: 100%;
  min-height: 400px;
  // background-color: red;
}
.blockContainerFifthOne {
  width: 100%;
  height: 60px;
  // background-color: darkcyan;
  font-size: 30px;
  display: flex;
}
.blockContainerFifthSecond {
  width: 100%;
  height: 85%;
  // background-color: darkgray;
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: center;
  align-items: center;
  column-gap: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.blockContainerFifthSecondImage {
  width: 400px;
  height: 300px;
  box-shadow: 0px 0px 6px 1px #7c7c7c;
  // background-color: darkkhaki;
}

#designBlockOne {
  width: 25%;
  background-color: aliceblue;
  padding-left: 10px;
  border-top-right-radius: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#designBlockTwo {
  width: 75%;
  height: 60px;
  // background-color: palegreen;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
}

@media (max-width: 1330px) {
  .blockContainerFifthSecond {
    grid-template-columns: auto auto;
    row-gap: 50px;
  }
}
@media (max-width: 1070px) {
  .blockContainerFifthOne {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
  .blockContainerFifthOne {
    font-size: 15px;
  }
}
@media (max-width: 570px) {
  #designBlockOne {
    width: 300px;
    font-size: 30px;
  }
  #designBlockTwo {
    display: none;
  }
}
@media (max-width: 880px) {
  .blockContainerFifthSecond {
    grid-template-columns: auto;
    row-gap: 50px;
  }

  .blockContainerFifthSecondImage {
    width: 300px;
    height: 100%;
  }
}
