.official {
  width: 100%;
  height: 40px;
  //  background-color: #e4f3ff;
  //  color: #151b20;

  background-color: #0a2125;
  color: white;

  display: flex;
}
.officialOne {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  font-size: 13px;
}
.officialTwo {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
.officialTwoTitle {
  text-decoration: none;
  color: white;
}

@media (max-width: 590px) {
  .officialOne {
    font-size: 11px;
  }
  .officialTwo {
    font-size: 11px;
  }
}

@media (max-width: 500px) {
  .officialOne {
    font-size: 7px;
  }
  .officialTwo {
    font-size: 7px;
  }
}
@media (max-width: 512px) {
  .official {
    display: none !important;
  }
}
@media (max-width: 375px) {
  .official {
    display: none !important;
  }
}
