@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600&display=swap");

.homeContainer {
  background-color: white;
}

.firstGrid {
  width: 100%;
  height: 400px;
  // background-color: aqua;
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  column-gap: 100px;
  row-gap: 50px;
}
.secondGrid {
  width: 100%;
  height: 800px;
  background-color: blue;
  display: flex;
}
